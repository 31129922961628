import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business Valuation Services | DC, MD & VA | DeBlanc"
        description="Need a business valuation? Get an objective analysis from the experts at DeBlanc +  Murphy. Serving the greater Washington, DC, area. Call today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Business Valuation Services</h1>
            <p className="mb-0 md:text-xl">
              How much is your business worth? Get an objective analysis from
              the experts at DeBlanc, Murphy & Murphy. Serving the greater Washington,
              DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Get The True Value of Your Business</h2>
                <p>
                  You might have an educated guess about the value of your
                  business based on simple financial portfolio factors, such as
                  total asset value, company bank account balances, etc. To get
                  the full picture, though, requires professional business
                  valuation services, which look at a multitude of factors.
                </p>
              </div>

              <div>
                <h3>Why Business Valuations Are Useful</h3>
                <p>
                A business valuation is a key piece of information for estate and gift tax planning. It can also be useful for:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>ESOP Valuations</li>
                  <li>Charitable Contributions</li>
                  <li>Portfolio Valuations</li>
                  <li>Purchase Price Allocations</li>
                  <li>Goodwill and Asset Impairment Testing</li>
                  <li>Succession Planning
                      </li>
                </ul>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={4} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={4} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.4 Business Valuation/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
